.datasets-page {
    width: 100%;
    height: 100%;
    padding: 24px 32px 32px 96px;

    .datasets_table .dataset_search input {
        width: 100%;
    }

    .content_block{
        display: grid;
        grid-template-columns: 1fr 346px;
        gap: 16px;
        width: 100%;
    }

    .datasets_table {
        width: 100%;
    }

    .datasets_table .table_main tr {
        grid-template-columns: 0.115fr 1.4fr 0.5fr 0.3fr 0.3fr;
    }

    .action {
        font-size: 16px;
        line-height: 24px;
        color: #0D4CD3;
        text-align: left;
        width: 100%;
    }

    .description {
        border: 1px solid #E4ECFD;
        padding: 1rem;
        font-size: 16px;
        line-height: 24px;
        color: #0B1F33;
        height: 100%;
    }

    .block-container {
        background-color: white;
        width: 100%;
        min-width: auto;
        height: 80vh;
        border-radius: 12px;
        gap: 16px;
        box-shadow: 0px 1px 4px 0px #E3EBFC, 0px 24px 48px 0px rgba(230, 235, 245, 0.4);
        padding: 16px;
        display: flex;
        flex-direction: column;
    }
}

.login-modal {
    .login_title {
        margin-bottom: 32px;
    }

    .not-login {
        margin: 24px auto 0;
    }
}